/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { BulkPresenter } from "@/app/ui/presenters/BulkPresenter";
import {
  BulkDetail,
  BulkEntities,
  Pagination,
  VersionNameData
} from "@/domain/entities/Bulk";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  BulkCustomerUploadCsvApiRequest,
  BulkUploadCsvApiRequest,
  BulkUploadXlsxApiRequest
} from "@/data/payload/api/BulkApiRequest";

export interface BulkState {
  bulkData: BulkEntities;
  detailBulk: BulkDetail;
  versionName: VersionNameData[];
  isLoading: boolean;
  isLoadingListData: boolean;
  isError: boolean;
  isErrorCause: string;
  responsePayload: ResponsePayload | null;
  isOpenUpload: boolean;
  message: string;
  type: string;
  isErrorUpload: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "bulk" })
class BulkStore extends VuexModule implements BulkState {
  public bulkData = new BulkEntities("", [], new Pagination(1, 10));
  public detailBulk = new BulkDetail(
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    0
  );
  public isLoading = false;
  public isLoadingListData = false;
  public isError = false;
  public isErrorCause = "";
  public isErrorUpload = false;
  public errorUploadConnection = "";
  public activity_name = "";
  public archive_type = "";
  public status = "";
  public page = 1;
  public limit = 10;
  public responsePayload = new ResponsePayload();
  public isOpenUpload = false;
  public message = "";
  public type = "";
  public convertRetailStart = new Date();
  public convertRetailEnd = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 3
  );
  public versionName: Array<VersionNameData> = [];
  public openNotification = false;

  @Action
  public getBulkList(params: {
    activityName: string;
    archiveType: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoadingList(true);
    const presenter = container.resolve(BulkPresenter);
    presenter
      .getListBulk(
        params.activityName,
        params.archiveType,
        params.status,
        params.page,
        params.limit
      )
      .then((res: BulkEntities) => {
        this.setBulkData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingList(false);
      });
  }

  @Action
  public setFirstPage() {
    this.setFirst();
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this.getBulkList({
      activityName: this.activity_name,
      archiveType: this.archive_type,
      status: this.status,
      page: this.page,
      limit: 10
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this.getBulkList({
        activityName: this.activity_name,
        archiveType: this.archive_type,
        status: this.status,
        page: this.page,
        limit: 10
      });
    }
  }

  @Action
  public search(value: string) {
    this.searchActivity(value);
    this.getBulkList({
      activityName: value,
      archiveType: this.archive_type,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public clear() {
    this.searchActivity("");
    this.getBulkList({
      activityName: "",
      archiveType: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  @Action
  public selectArchiveType(value: string) {
    this.selectType(value);
    this.getBulkList({
      activityName: "",
      archiveType: this.archive_type,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this.getBulkList({
      activityName: this.activity_name,
      archiveType: this.archive_type,
      status: this.status,
      page: value,
      limit: 10
    });
  }

  @Action
  public selectStatusUpload(value: string) {
    this.selectStatus(value);
    this.getBulkList({
      activityName: "",
      archiveType: this.archive_type,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Mutation
  public setLoadingList(isLoading: boolean) {
    this.isLoadingListData = isLoading;
  }

  @Mutation
  public async setBulkData(res: BulkEntities) {
    this.bulkData = res;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async searchActivity(value: string) {
    this.activity_name = value;
  }

  @Mutation
  public async selectType(value: string) {
    this.archive_type = value;
  }

  @Mutation
  public async selectStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  @Mutation
  public setOpenNotification(bool: boolean) {
    this.openNotification = bool;
  }

  @Mutation
  public setError(bool: boolean) {
    this.isError = bool;
  }

  @Mutation
  public setErrorUpload(bool: boolean) {
    this.isErrorUpload = bool;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.isErrorCause = value;
  }

  @Mutation
  public setErrorCauseConnection(value: string) {
    this.errorUploadConnection = value;
  }

  @Mutation
  public setIsOpenUpload(bool: boolean) {
    this.isOpenUpload = bool;
  }

  @Mutation
  public async setFirst() {
    this.page = 1;
  }

  @Mutation
  public async setPage(value: number) {
    this.page = value;
  }

  @Mutation
  public SET_RESPONSE_PAYLOAD(res: ResponsePayload) {
    this.responsePayload = res;
  }

  @Mutation
  public setMessage(value: string) {
    this.message = value;
  }

  @Mutation
  public setType(value: string) {
    this.type = value;
  }

  @Mutation
  public setRetailStart(date: Date) {
    this.convertRetailStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
  @Mutation
  public setRetailEnd(date: Date) {
    this.convertRetailEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  @Mutation
  public setBulkDetail(data: BulkDetail) {
    this.detailBulk = data;
  }

  @Action
  public async _onBulkUploadCsv(params: {
    archiveType: string;
    activityName: string;
    archiveFile: any;
    startDate: string;
    endDate: string;
    isScheduler: boolean;
    customStatus: string;
    commoditySurchargeStatus: string,
    isBulkUploadForm : boolean
  }) {
    const fileName = params.archiveFile && params.archiveFile.name;
    this.setLoading(true);
    const presenter = container.resolve(BulkPresenter);
   return await presenter
      .bulkUpload(
        new BulkUploadCsvApiRequest(
          params.archiveType,
          params.activityName,
          params.archiveFile,
          params.startDate,
          params.endDate,
          params.isScheduler,
          params.customStatus,
          params.commoditySurchargeStatus
        )
      )
      .then(res => {
        this.SET_RESPONSE_PAYLOAD(res);
        this.setType("Aktivitas Upload Sukses");
        this.setMessage(`Aktivitas “${fileName}” sukses terupload`);
        this.setErrorUpload(false);
        this.setErrorCause("");
        this.setOpenNotification(true);

        setTimeout(() => {
          this.setLoading(false);
          if (params.isBulkUploadForm){
            this.setIsOpenUpload(false);
          }
          this.setType("");
          this.setMessage("");
        }, 3000);
        return true;
      })
      .catch(err => {
        if (err.response) {
          this.setMessage(`Aktivitas “${fileName}” gagal terupload`);
          this.setType("Aktivitas Upload Gagal");
          this.setOpenNotification(true);
          setTimeout(() => {
            this.setLoading(false);
            if (params.isBulkUploadForm){
              this.setIsOpenUpload(false);
            }
            this.setType("");
            this.setMessage("");
          }, 3000);
        } else {
          this.setErrorUpload(true);
          this.setErrorCauseConnection(err.response ? "server" : "internet");
          this.setLoading(false);
        }
        return false;
      });
  }

  @Action
  public async _onBulkCustomerUploadCsv(params: {
    activityName: string;
    archiveFile: any;
    isBulkUploadForm : boolean
  }) {
    const fileName = params.archiveFile && params.archiveFile.name;
    this.setLoading(true);
    const presenter = container.resolve(BulkPresenter);
   return await presenter
      .bulkCustomerUpload(
        new BulkCustomerUploadCsvApiRequest(
          params.activityName,
          params.archiveFile
        )
      )
      .then(res => {
        this.SET_RESPONSE_PAYLOAD(res);
        this.setType("Aktivitas Upload Sukses");
        this.setMessage(`Aktivitas “${fileName}” sukses terupload`);
        this.setErrorUpload(false);
        this.setErrorCause("");
        this.setOpenNotification(true);

        setTimeout(() => {
          this.setLoading(false);
          if (params.isBulkUploadForm){
            this.setIsOpenUpload(false);
          }
          this.setType("");
          this.setMessage("");
        }, 3000);
        return true;
      })
      .catch(err => {
        if (err.response) {
          this.setMessage(`Aktivitas “${fileName}” gagal terupload`);
          this.setType("Aktivitas Upload Gagal");
          this.setOpenNotification(true);
          setTimeout(() => {
            this.setLoading(false);
            if (params.isBulkUploadForm){
              this.setIsOpenUpload(false);
            }
            this.setType("");
            this.setMessage("");
          }, 3000);
        } else {
          this.setErrorUpload(true);
          this.setErrorCauseConnection(err.response ? "server" : "internet");
          this.setLoading(false);
        }
        return false;
      });
  }

  @Action
  public _onGetDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(BulkPresenter);
    presenter
      .getDetailBulk(id)
      .then((res: BulkDetail) => {
        this.setBulkDetail(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public handleError() {
    this.setErrorUpload(false);
  }

  @Action
  public async _onBulkUploadXlsx(params: BulkUploadXlsxApiRequest) {
    const fileName = params.archiveFile && params.archiveFile.name;
    this.setLoading(true);
    const presenter = container.resolve(BulkPresenter);
    await presenter
      .addBulkUploadRetailPrice(params)
      .then(res => {
        this.SET_RESPONSE_PAYLOAD(res);
        this.setType("Aktivitas Upload Sukses");
        this.setMessage(`Aktivitas “${fileName}” sukses terupload`);
        this.setErrorUpload(false);
        this.setErrorCause("");
        this.setOpenNotification(true);

        setTimeout(() => {
          this.setLoading(false);
          this.setIsOpenUpload(false);
          this.setType("");
          this.setMessage("");
        }, 3000);
      })
      .catch(err => {
        if (err.response) {
          this.setMessage(`Aktivitas “${fileName}” gagal terupload`);
          this.setType("Aktivitas Upload Gagal");
          this.setOpenNotification(true);

          setTimeout(() => {
            this.setLoading(false);
            this.setIsOpenUpload(false);
            this.setType("");
            this.setMessage("");
          }, 3000);
        } else {
          this.setErrorUpload(true);
          this.setErrorCauseConnection(err.response ? "server" : "internet");
          this.setLoading(false);
        }
      });
  }
}

export const BulkController = getModule(BulkStore);
